<template>

    <div class="info-list__content--dropdown">
        <table class="list">
            <tr v-for="(appo,index) in appointments.days" :key="index" class="dropdown-list" :class="isCurrentlyActive(appo)">
                <td class="date-cell">
                    <span v-if="appo.date==null && appo.wday!=null">
                        {{ getWeekday(appo.wday).substring(0,2) }},
                    </span>
                    <span v-if="appo.date!=null">
                        {{ formatDate(appo.date) }}
                    </span>
                </td>
                <td> 
                    <div v-for="(time,index) in appo.times" :key="index">{{formatTime(time.time_from)}} - {{formatTime(time.time_to)}} Uhr</div>
                </td>
            </tr>
        </table>

        <div class="exceptions" v-if="appointments.exceptions!=null && appointments.exceptions.length>0">
            <div>Ausnahmen:</div>
            <div v-for="(exc,index) in appointments.exceptions" :key="index"> {{formatDate(exc.start_date)}} - {{formatDate(exc.end_date)}} </div>
        </div>
    </div>

</template>

<script>

    import {getWeekday, formatTime, formatDate, getCurrentDate, getCurrentTime, getCurrentWeekDay,isAppointmentNow} from '@/utils/appointment-helpers';

    export default {
        name: 'displayAppointments',
        props: {
            appointments: {
                type: Object,
                default: null,
            },
            active:{
                type: Boolean,
                default: false,
            }
        },
        data() {
            return {
            }
        },
        computed:{
            currentDate(){
                return getCurrentDate();
            },
            currentTime(){
                return getCurrentTime();
            },
            currentWeekDay(){
                return getCurrentWeekDay();
            }
        },
        methods: {
            getWeekday,
            formatTime,
            formatDate,
            getCurrentDate, 
            getCurrentTime, 
            isAppointmentNow,
            getCurrentWeekDay,
            isCurrentlyActive(appointment){
                if(appointment!=null && isAppointmentNow(appointment) && this.active){
                    return "active"
                }
                return 'inactive';
            }
        }
    }
</script>

<style lang="scss" scoped>
    @import '@/scss/_variables.scss';

    .display-appointments .date-cell{
        vertical-align: top;
        padding-right: 5px;
    }

    .display-appointments .exceptions{
        margin-top: 10px;
    }

    .display-appointments .active{
        font-family: 'elliot-bold', sans-serif;
        font-weight: bold;
    }

</style>