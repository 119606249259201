    export function getWeekday(wday){
        var day = "";
        switch(wday){
            case 1:
            day = "Montag";
            break;
            case 2:
            day = "Dienstag";
            break;
            case 3:
            day = "Mittwoch";
            break;
            case 4:
            day = "Donnerstag";
            break;
            case 5:
            day = "Freitag";
            break;
            case 6:
            day = "Samstag";
            break;
            case 7:
            day = "Sonntag";
            break;
            case 0:
            day = "Sonntag";
            break;
        }
        return day;
    }

    export function getNextDateForWday(wday){
        var now = new Date();    
        now.setDate(now.getDate() + (wday+(7-now.getDay())) % 7);
        return now;
    }

    export function addZero(i) {
      if (i < 10) {i = "0" + i}
          return i;
  }

  export function getReadableTime(timeValue) {
    if (timeValue != null) {
      let d = timeValue === null ? new Date() : new Date(timeValue);
      let h = addZero(d.getHours());
      let m = addZero(d.getMinutes());
      let time = h + ":" + m;
      return time;
  }
  return ''
}

export function compareDates(a,b){
    if(a!==null && b!==null){
        a =  new Date(a);
        b = new Date(b);

        /*if a is bigger than b return 1*/
        if(a>b){
            return 1;
        }

        /*if b is bigger than a return -1*/
        if(a<b){
            return -1;
        }

        /*if they are the same return 0*/
        if(a.getTime() === b.getTime()){
            return 0;
        }

    }
    /*if one or both values are zero, then return null*/
    return null;
}

export function compareAndSwitchDates(a,b){
    var res = compareDates(a,b);
    if(res === null || res === 0 || res == -1){
        /*means that we either got no valid values for a and/or b or that the dates are the same or that b is already the end-date and a is the start-date -> do nada*/
        return {start:a, end:b};
    }
    return {start:b, end:a}; 
}

export function getReadableDate(dateValue, type="full") {
    if (dateValue != null) {
        let d = dateValue === null ? new Date() : new Date(dateValue);
        if (type=="full"){
            let day = getWeekday(d.getDay()).substring(0, 2);
            let month = getMonth(d.getMonth()+1).substring(0, 3);
            return day + ', ' + d.getDate() + '.' + ' ' + month + ' ' + d.getFullYear();
        }
        else {
            return addZero(d.getDate())+'.'+addZero(d.getMonth()+1)+'.'+d.getFullYear();
        }
    }
    return '';
}

export function getFilterDate(dateValue) {
    if (dateValue != null) {
        let d = dateValue === null ? new Date() : new Date(dateValue);
        return d.getFullYear()+'-'+addZero(d.getMonth()+1)+'-'+addZero(d.getDate());
    }
    return '';
}

export function getMonth(month){
    var amonth = "";
    switch(month){
        case 1:
        amonth = "Januar";
        break;
        case 2:
        amonth = "Februar";
        break;
        case 3:
        amonth = "März";
        break;
        case 4:
        amonth = "April";
        break;
        case 5:
        amonth = "Mai";
        break;
        case 6:
        amonth = "Juni";
        break;
        case 7:
        amonth = "Juli";
        break;
        case 8:
        amonth = "August";
        break;
        case 9:
        amonth = "September";
        break;
        case 10:
        amonth = "Oktober";
        break;
        case 11:
        amonth = "November";
        break;
        case 12:
        amonth = "Dezember";
        break;
    }
    return amonth;
}

export function formatTime(time){
    if(time!=null && time!=""){
        var timeParts = time.split(':');
        return timeParts[0] + ':' + timeParts[1];
    }
    return "";
}

export function formatDate(date){
    if(date!=null && date!=""){
        var dateParts = date.split('-');
        return dateParts[2] + '.' + dateParts[1] + '.' + dateParts[0];
    }
    return "";
}

export function getCurrentDate(){
    var currentdate = new Date(); 
    var date = currentdate.getDate() + "."
    + (currentdate.getMonth()+1)  + "." 
    + currentdate.getFullYear() 
    return date;
}


export function getCurrentSystemDate(){
    var currentdate = new Date(); 
    var date = currentdate.getFullYear() + "-" + (currentdate.getMonth()+1)  + "-" + currentdate.getDate(); 
    return date;
}


export function getDateFromDateParam(dateParam){
    var currentdate = dateParam; 
    var date = currentdate.getFullYear() + "-" + (currentdate.getMonth()+1)  + "-" + currentdate.getDate(); 
    return date;
}

export function getCurrentTime(){
    var currentdate = new Date();
    var time = currentdate.getHours() + ":"  
    + currentdate.getMinutes() + ":" 
    + currentdate.getSeconds();
    return time;
}

export function getCurrentWeekDay(){
    var currentdate = new Date();
    var wday = currentdate.getDay();
    /*we need to add +1 wo wday for our format*/
    return wday++;
}

/*checks if the appointment is today*/
export function isAppointmentToday(appointment){
    var currentDate = getCurrentSystemDate();
    var currentWeekDay = getCurrentWeekDay();
    var isCurrentDate = false;
    var isCurrentWeekDay = false;

    /*check if the appointment has a date and if that date is today*/
    if(appointment.date!=null){
      var today = Date.parse(currentDate);
      var appoDate = Date.parse(appointment.date);
      isCurrentDate = today==appoDate?true:false;
  }

  if(appointment.wday!=null){
    isCurrentWeekDay = appointment.wday==currentWeekDay?true:false;
}

/*do we have a hit?*/
if(appointment.date!==null){
    if(isCurrentDate && isCurrentWeekDay){
        return true;
    }
    return false;
}
else if(isCurrentWeekDay){
    return true;
}

return false;
}

/*checks if the appointment is now*/
export function isAppointmentNow(appointment){
    var currentTime = getCurrentTime();
    var isCurrentTime = false;
    var isToday = isAppointmentToday(appointment);

    /*check if we have a hit for current date or weekday - only then do we have to look for the time*/
    if(isToday){
        if(appointment.times!=null && appointment.times.length>0){
            for(var i=0;i<appointment.times.length;i++){
                var time = appointment.times[i];
                isCurrentTime = checkCurrentTimeBetween(time.time_from,time.time_to,currentTime);
                if(isCurrentTime){
                    /*look no more ...*/
                    break;
                }
            }
        }
    }

    /*do we have a hit?*/
    if(isToday && isCurrentTime){
        return true;
    }

    return false;

}

/**
 * check if this a certain set of appointments is within an exception range
 * @param  {[type]}  appointments [description]
 * @return {Boolean}              [description]
 */
 export function isInExceptionRange(appointments){
    var isInExceptionRange = false;

    var currentDate = getCurrentDate();

    /*check if there are exceptions*/
    if(Object.prototype.hasOwnProperty.call(appointments,'exceptions') && appointments.exceptions!=null){
        var exceptions = appointments.exceptions;
        /*so let's check if the current date is in exceptions*/
        for(var l = 0; l<exceptions.length; l++){
            var exception = exceptions[l];
            isInExceptionRange = checkCurrentDateBetween(exception.start_date,exception.end_date,currentDate);
            if(isInExceptionRange){
                return exception;
            }
        }
    }
    return null;
}

/*check if one of the appointments is now*/
export function isOneOfTheAppointmentsNow(appointments){

    /*check if there is an appointment for today*/
    var today = isOneOfTheAppointmentsToday(appointments);

    /*check if we got an appointment that is today*/
    if(today!=null){
        /*check if that Appointment is now*/
        if(isAppointmentNow(today)){
            return today;
        }
    }
    return null;
}

/*check if one of the appointments is now*/
export function isOneOfTheAppointmentsToday(appointments){

    var exceptions = null;
    var currentDate = getCurrentSystemDate();

    /*if the currentdate is in the exception-range then return null*/
    if(isInExceptionRange(appointments)!=null){
        return null;
    }


    if(appointments!=null && Object.prototype.hasOwnProperty.call(appointments,'days') ){
        for(var i = 0; i < appointments.days.length; i++){
            var appointment = appointments.days[i];
            if(isAppointmentToday(appointment)){
                /*return the appointment if the appointment is today*/
                return appointment;
            }
        }
    }
    /*return null if the appointment is not today*/
    return null;
}

export function checkCurrentTimeBetween(start,end,current){
    var Time = function(timeString) {
        var t = timeString.split(":");
        this.hour = parseInt(t[0]);
        this.minutes = parseInt(t[1]);
        this.isBiggerThan = function(other) { 
            return (this.hour > other.hour) || (this.hour === other.hour) && (this.minutes > other.minutes);
        };
    }

    var timeIsBetween = function(start, end, check) {
        return (start.hour <= end.hour) ? check.isBiggerThan(start) && !check.isBiggerThan(end)
        : (check.isBiggerThan(start) && check.isBiggerThan(end)) || (!check.isBiggerThan(start) && !check.isBiggerThan(end));    
    }

    var openTime = new Time(formatTime(start));
    var closeTime = new Time(formatTime(end));
    var checkTime = new Time(formatTime(current));

    return timeIsBetween(openTime, closeTime, checkTime);
}

export function checkCurrentDateBetween(start,end,current){
    start = Date.parse(start);
    end = Date.parse(end);
    current = Date.parse(current);

    return (current<start || current > end)?false:true;
}

export function checkCurrentTimeBefore(start,current){
    var Time = function(timeString) {
        var t = timeString.split(":");
        this.hour = parseInt(t[0]);
        this.minutes = parseInt(t[1]);
        this.isBiggerThan = function(other) { 
            return (this.hour > other.hour) || (this.hour === other.hour) && (this.minutes > other.minutes);
        };
    }

    var timeIsBefore = function(start,check) {
        return check.isBiggerThan(start)?false:true;    
    }

    var openTime = new Time(formatTime(start));
    var checkTime = new Time(formatTime(current));

    return timeIsBefore(openTime,checkTime);
}

export function getNextAppointment(appointments, date=null){
    if(appointments != null && Object.prototype.hasOwnProperty.call(appointments,'days') && appointments.days!=null && appointments.days.length>0){

        var exceptions = null;
        var isInExceptionRange = false;
        var currentTime = getCurrentTime();
        var currentDate = getCurrentSystemDate();
        var currentWeekDay = getCurrentWeekDay();

        /*if we need the appointments for another date ...*/
        if(date != null){
            var theDate = new Date(date); 
            currentDate = getDateFromDateParam(date);
            currentWeekDay = theDate.getDay();
            currentWeekDay = currentWeekDay++;
            currentTime = "00:00:00";
        }

        /*check if there are exceptions*/
        if(Object.prototype.hasOwnProperty.call(appointments,'exceptions') && appointments.exceptions!=null){
            exceptions = appointments.exceptions;
            /*so let's check if the current date is in exceptions*/
            for(var l = 0; l<exceptions.length; l++){
                var exception = exceptions[l];
                isInExceptionRange = checkCurrentDateBetween(exception.start_date,exception.end_date,currentDate);
                if(isInExceptionRange){
                    /**if the currentDate is within an exception-range we'll manipulate the current date and the currentWeekday
                    to find the next valid Date after the exception-range*/
                    currentDate = exception.end_date;
                    var aDate = new Date(currentDate);
                    currentWeekDay = aDate.getDay();
                    /*cancel for if isInExceptionRange becomes true*/
                    break;
                }
            }
        }

        /*the actual appointments*/
        appointments = appointments.days;

        /*new variables for the next Date*/
        var nextTime = null;
        var next = {date:null, wday:null, time:null};

        var checkAppoTimes = function(appo){
         for(var j=0;j<appo.times.length;j++){
            var time = appo.times[j];
            if(checkCurrentTimeBefore(time.time_from,currentTime)){
                return time;
            }
        }
        return null;
    }

    for(var i=0;i<appointments.length;i++){
        var appointment = appointments[i];

        if(appointment.date!=null){
            /*let's check if the appointment is today*/
            var today = Date.parse(currentDate);
            var appoDate = Date.parse(appointment.date);

            if(today == appoDate) {
                nextTime = checkAppoTimes(appointment);
                /*check if we found a next-time*/
                if(nextTime!=null){
                    /*stop the for-loop - we have a winner*/
                    next.date=appointment.date;
                    next.time=nextTime;
                    return next;
                }
            }

            /*check for the next appointment*/
            if(today < appoDate){
                nextTime = appointment.times!=null && appointment.times.length>0?appointment.times[0]:null;
                /*check if we found a next-time*/
                if(nextTime!=null){
                    /*stop the for-loop - we have a winner*/
                    next.date=appointment.date;
                    next.time=nextTime;
                    return next;
                }
            }
        }
        else if(appointment.date == null && appointment.wday != null){
            if(appointment.wday == currentWeekDay) {
                nextTime = checkAppoTimes(appointment);
                /*check if we found a next-time*/
                if(nextTime!=null){
                    /*stop the for-loop - we have a winner*/
                    next.wday=appointment.wday;
                    next.time=nextTime;
                    return next;
                }
            }

            /*check for the next appointment*/
            if(appointment.wday > currentWeekDay){
                nextTime = appointment.times!=null && appointment.times.length>0?appointment.times[0]:null;
                /*check if we found a next-time*/
                if(nextTime!=null){
                    /*stop the for-loop - we have a winner*/
                    next.wday=appointment.wday;
                    next.time=nextTime;
                    return next;
                }
            }
        }
    }

        /**now let's see we have gone through all the appointments and haven't found a match if we're here -
        * that could mean that we are at the end of a week or there are no more new appointments. So let's look at the first appointment and check if we could use that
        */
        if(appointments[0].wday!=null){
            appointment = appointments[0];
            nextTime = appointment.times!=null && appointment.times.length>0?appointment.times[0]:null;
            /*check if we found a next-time*/
            if(nextTime!=null){
                /*stop the for-loop - we have a winner*/
                next.date=appointment.date;
                next.wday=appointment.wday;
                next.time=nextTime;
                return next;
            }
        }

    }
    return null;
}

export function smartSubStr(str, len) {
    var temp = str.substr(0, len);
    if(temp.lastIndexOf('<') > temp.lastIndexOf('>')) {
        temp = str.substr(0, 1 + str.indexOf('>', temp.lastIndexOf('<')));
    }
    return temp;
}

export function showLoader(loader){
    loader=this.$loading.show({
        container: this.fullPage ? null : this.$refs.formContainer,
        canCancel: true,
        color: "#007BFF",
        loader: 'dots',
        opacity:0.7,
        height:128,
        width:128,
        onCancel: this.onCancel,
    });
    return loader;
}


export function hideLoader(loader){
    loader.hide();
    return loader;
}


/**
 * sorts a given array of objects case-insensitive alphabetically and considering a certain locale  
 */
 export function sortByProperty(objects, property, langcode){ 
    objects.sort(function(a, b) {
     return a[property].toLowerCase().localeCompare(b[property].toLowerCase(),langcode);
 });
    return objects;
}


/**
 * get a placeholder image if no other image is given
 */
 export function getPlaceholderImage(category = null){
    /*we pack all the images in an array so we can return a random one*/
    var images = [
    "/assets/categories/bildungwissenschaft_default.png",
    "/assets/categories/kunstgalerie_default.png",
    "/assets/categories/musiktanz_default.png",
    "/assets/categories/cafesbarclub_default.png",
    "/assets/categories/filmmedien_default.png",
    "/assets/categories/kulturkreativraum_default.png",
    "/assets/categories/museumausstellung_default.png",
    "/assets/categories/religion_default.png",
    "/assets/categories/theateroper_default.png"];

    var image = null;

    /*if a category is given we'll look for an image matching that category*/
    if(category != null){
        switch(category){
            case "Bildung & Wissenschaft":
            image = images[0];
            break;
            case "Kunst & Galerien":
            image = images[1];
            break;
            case "Musik & Tanz":
            image = images[2];
            break;
            case "Cafés - Bars & Clubs":
            image = images[3];
            break;
            case "Film & Medien":
            image = images[4];
            break;
            case "Kultur- & Kreativräume":
            image = images[5];
            break;
            case "Museen & Stadtgeschichte":
            image = images[6];
            break;
            case "Religionskultur":
            image = images[7];
            break;
            case "Theater & Oper":
            image = images[8];
            break;
            default:
            image = images[Math.floor(Math.random()*images.length)];
            break;
        }
    }

    if(image == null){
        image = images[Math.floor(Math.random()*images.length)];
    }

    return image;
}

 /**
 * Extract the allowedFieldValues from a FieldTypeResource
 */
 export function getAllowedValuesFromFieldTypes(data){
    if(Object.prototype.hasOwnProperty.call(data,'field_type_values') && data.field_type_values!=null){
      /*we'll need to extract the actual values from that array*/
      var allowedValues = [];
      for(var i = 0; i<data.field_type_values.length; i++){
        var fTypeValueId = data.field_type_values[i].id;
        var value = data.field_type_values[i].allowed_value;
        if(value!=null && value != ""){
            allowedValues.push({'label': value, 'value': value, 'id':fTypeValueId});
        }
    }
    return allowedValues;
}
return null;
}

export function mapOrder(array, order, key) {
    var map = new Map();
    var index = 0;
    var tmp;
    if(!array || !order || array.length!==order.length)
      return array;
  array.forEach(function(it) {
    map.set(it[key], index++);
});
  order.forEach(function(it) {
      if(map.get(it) === undefined) return array;
  });
  index--;
  for (; index >= 0; index--) {
    if (array[index][key] !== order[index]) {
        tmp = array[index];
        array[index] = array[map.get(order[index])];
        array[map.get(order[index])] = tmp;
        map.set(tmp[key], map.get(order[index]));
    }
}
return array;
}